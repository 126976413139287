<template>
  <v-app>
    <v-main>
      <form>
        <v-container grid-list-md class="ghost">
          <v-row class="justify-space-between">
            <v-col cols="10" sm="3">
              <v-text-field
                label="Patient ID / Name"
                outlined
                class="iol-v-text-field"
                ref="first"
                v-model="inputs.patientID"
              />
            </v-col>
            <v-col cols="10" sm="3">
              <v-select
                label="Keratometric Index"
                outlined
                class="smooth iol-v-select"
                v-model="inputs.keratometricIndex"
                :items="$parent.keratometricItems"
                return-object
                required
              />
            </v-col>
            <v-col cols="10" sm="3">
              <v-select
                label="Biometer"
                :items="$parent.biometerItems"
                item-text="text"
                outlined
                required
                class="smooth iol-v-select"
                v-model="inputs.biometer"
                return-object
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>
          <v-sheet v-bind:class="{ 'iol-right-v-sheet': inputs.side, 'iol-left-v-sheet': !inputs.side }">
            <v-row class="align-baseline">
              <v-col cols="2" md="5" class="switch-eye-label-left">
                <transition name="fade">
                  <div v-if="inputs.side">
                    <h1>OD</h1>
                  </div>
                </transition>
              </v-col>
              <v-spacer />
              <v-col cols="2" md="1">
                <v-switch
                  v-model="inputs.side"
                  class="switch-eye"
                  :messages="inputs.side ? 'switch to left eye' : 'switch to right eye'"
                />
              </v-col>
              <v-spacer />
              <v-col cols="2" md="5" class="switch-eye-label-right">
                <transition name="fade">
                  <div v-if="!inputs.side">
                    <h1>OS</h1>
                  </div>
                </transition>
              </v-col>
            </v-row>
            <v-row class='justify-center'>
              <v-col cols="11">
                <p class="text-justify font-italic">If the first eye has already been operated, enter its preoperative biometry, the A constant and power of the implanted IOL, and the resulting postoperative spherical equivalent. The Second Eye function is only available for physiological, non-operated eyes and post-myopic / post-hyperopic LVC eyes, implanted using the same IOL model. If the AL difference is > 0.65mm or the ARC difference is > 0.22mm, it will automatically be deactivated.</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-sheet class="iol-v-sheet">
                  <v-row>
                    <v-col cols="6" md="3">
                      <v-select
                        label="IOL Model"
                        :items="$parent.IOLModelItems"
                        item-text="text"
                        outlined
                        class="smooth iol-v-select"
                        v-model="inputs.IOLModel"
                        return-object
                        required
                        @change="IOLModelUpdated()"
                        @blur="IOLModelUpdated()"
                      />
                    </v-col>
                    <v-spacer />
                    <v-col cols="6" md="3">
                      <v-text-field
                        label="A constant"
                        class="iol-v-text-field"
                        outlined
                        v-model="inputs.aconst"
                        :error-messages="aconstErrors"
                        :disabled="inputs.is60MA"
                        :required="inputs.is60MA"
                        type="number"
                        step="0.01"
                        @input="aconstUpdated()"
                        @blur="aconstUpdated()"
                      />
                    </v-col>
                    <v-spacer />
                    <v-col cols="10" md="4">
                      <v-text-field
                        label="Target refraction (D)"
                        class="iol-v-text-field"
                        outlined
                        v-model="inputs.target"
                        :error-messages="TargetErrors"
                        required
                        type="number"
                        step="0.01"
                        @input="$v.inputs.target.$touch()"
                        @blur="$v.inputs.target.$touch()"
                      />
                    </v-col>
                    <v-col cols="6" md="5">
                      <v-select
                        label="Vitreous type"
                        :items="$parent.vitreousItems"
                        item-text="type"
                        outlined
                        class="smooth iol-v-select"
                        v-model="inputs.vitreousType"
                        return-object
                        readonly
                        @change="vitreousTypeUpdated()"
                        @blur="vitreousTypeUpdated()"
                      />
                    </v-col>
                    <v-spacer />
                    <v-col cols="6" md="5">
                      <v-tooltip bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            label="Custom vitreous"
                            class="iol-v-text-field"
                            outlined
                            v-model="inputs.nvit"
                            :error-messages="vitreousErrors"
                            required
                            :disabled="inputs.isVitreous"
                            type="number"
                            step="0.0001"
                            @input="$v.inputs.nvit.$touch()"
                            @blur="$v.inputs.nvit.$touch()"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <span>Enter the refractive index of the material (ex. silicon oil)</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="5">
                      <v-text-field
                        label="AL (mm)"
                        class="iol-v-text-field"
                        outlined
                        v-model="inputs.AL"
                        :error-messages="ALErrors"
                        required
                        type="number"
                        step="0.01"
                        @input="$v.inputs.AL.$touch()"
                        @blur="$v.inputs.AL.$touch()"
                      />
                      <v-text-field
                        label="K1 (D)"
                        class="iol-v-text-field"
                        outlined
                        v-model="inputs.K1"
                        :error-messages="K1Errors"
                        required
                        type="number"
                        step="0.01"
                        @input="$v.inputs.K1.$touch()"
                        @blur="$v.inputs.K1.$touch()"
                      />
                      <v-text-field
                        label="K2 (D)"
                        class="iol-v-text-field"
                        outlined
                        v-model="inputs.K2"
                        :error-messages="K2Errors"
                        required
                        type="number"
                        step="0.01"
                        @input="$v.inputs.K2.$touch()"
                        @blur="$v.inputs.K2.$touch()"
                      />
                      <v-text-field
                        label="ACD (mm)"
                        class="iol-v-text-field"
                        outlined
                        v-model="inputs.ACD"
                        :error-messages="ACDErrors"
                        required
                        type="number"
                        step="0.01"
                        @input="$v.inputs.ACD.$touch()"
                        @blur="$v.inputs.ACD.$touch()"
                      />
                    </v-col>
                    <v-spacer />
                    <v-col cols="6" sm="6">
                      <v-sheet class="iol-opt-v-sheet">
                        <h3>Optional</h3><br />
                        <v-text-field
                          label="LT (mm)"
                          class="iol-v-text-field"
                          outlined
                          v-model="inputs.LT"
                          :error-messages="LTErrors"
                          type="number"
                          step="0.01"
                          @input="$v.inputs.LT.$touch()"
                          @blur="$v.inputs.LT.$touch()"
                        />
                        <v-text-field
                          label="CCT (µm)"
                          class="iol-v-text-field"
                          outlined
                          type="number"
                          step="0"
                          v-model="inputs.CCT"
                          :error-messages="CCTErrors"
                          @input="$v.inputs.CCT.$touch()"
                          @blur="$v.inputs.CCT.$touch()"
                        />
                      </v-sheet>
                    </v-col>
                  </v-row>
                  <v-row class="justify-md-space-between">
                    <v-col cols="4" md="3">
                      <v-switch
                        label="ICL"
                        v-model="inputs.isICL"
                        prepend-icon="info"
                      >
                        <v-tooltip bottom slot="prepend">
                          <template v-slot:activator="{ on, attrs }">
                              <v-icon v-on="on" v-bind="attrs" color="primary">
                                info
                              </v-icon>
                            </template>
                          <span>If an Implantable Collamer Lens was implanted at the time of biometry, activate this option. It will adapt the AL measurement</span>
                        </v-tooltip>
                      </v-switch>
                    </v-col>
                    <v-col cols="10" md="8">
                      <v-sheet class="iol-opt-v-sheet">
                        <h3>Optional</h3><br />
                        <v-row class="justify-left">
                          <v-col cols="8" md="8" offset="1">
                            <v-select
                              label="Corneal refractive surgery"
                              :items="$parent.cornealLaserTypeItems"
                              item-text="text"
                              outlined
                              class="smooth iol-v-select"
                              v-model="inputs.cornealLaserType"
                              :disabled="!inputs.isICL"
                              return-object
                            />
                          </v-col>
                        </v-row>
                        <v-row class="justify-center">
                          <v-col cols="8" md="8">
                            <v-tooltip bottom slot="append">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  label="Pre-LVC anterior corneal radius (mm)"
                                  outlined
                                  class="iol-v-text-field"
                                  v-model="inputs.preLvcArc"
                                  :error-messages="preLvcArcErrors"
                                  :disabled="!inputs.isICL"
                                  type="number"
                                  step="0.01"
                                  @change="$v.inputs.preLvcArc.$touch()"
                                  @blur="$v.inputs.preLvcArc.$touch()"
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </template>
                              <span>If available, enter the anterior corneal radius measured before laser vision correction, in millimeters</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row class="justify-center">
                          <v-col cols="8" md="8">
                            <v-tooltip bottom slot="append">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  label="Corrected ametropia (D)"
                                  outlined
                                  class="iol-v-text-field"
                                  v-model="inputs.laserAmount"
                                  :error-messages="laserAmountErrors"
                                  :disabled="!inputs.isICL"
                                  type="number"
                                  step="0.01"
                                  @change="$v.inputs.laserAmount.$touch()"
                                  @blur="$v.inputs.laserAmount.$touch()"
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </template>
                              <span>If available, enter the absolute value of the corrected ametropia, in diopters</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
              <v-col cols="12" md="6">
                <v-sheet class="iol-v-sheet" elevation="1" outlined>
                  <div class="bg-blue text-center rounded-lg">
                    <span>
                      <h3>Controlateral Operated Eye Information</h3>
                    </span>
                  </div>
                  <br />
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Implanted A constant"
                        class="iol-v-text-field"
                        outlined
                        v-model="inputs.aconst2"
                        required
                        readonly
                        disabled
                        :error-messages="aconst2Errors"
                        type="number"
                        step="0.01"
                        @input="$v.inputs.aconst2.$touch()"
                        @blur="$v.inputs.aconst2.$touch()"
                      />
                    </v-col>
                    <v-spacer />
                    <v-col cols="12" md="5">
                      <v-text-field
                        label="Implanted IOL power"
                        class="iol-v-text-field"
                        outlined
                        v-model="inputs.power2"
                        required
                        :error-messages="power2Errors"
                        type="number"
                        step="0.01"
                        @input="$v.inputs.power2.$touch()"
                        @blur="$v.inputs.power2.$touch()"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Postop. spherical equivalent (D)"
                        class="iol-v-text-field"
                        outlined
                        v-model="inputs.SE2"
                        required
                        :error-messages="SE2Errors"
                        type="number"
                        step="0.001"
                        @input="$v.inputs.SE2.$touch()"
                        @blur="$v.inputs.SE2.$touch()"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="6">
                      <v-text-field
                        label="Preop. AL (mm)"
                        class="iol-v-text-field"
                        outlined
                        v-model="inputs.AL2"
                        :error-messages="AL2Errors"
                        type="number"
                        step="0.01"
                        @input="$v.inputs.AL2.$touch()"
                        @blur="$v.inputs.AL2.$touch()"
                      />
                      <v-text-field
                        label="Preop. K1 (D)"
                        class="iol-v-text-field"
                        outlined
                        v-model="inputs.K12"
                        required
                        :error-messages="K12Errors"
                        type="number"
                        step="0.01"
                        @input="$v.inputs.K12.$touch()"
                        @blur="$v.inputs.K12.$touch()"
                      />
                      <v-text-field
                        label="Preop. K2 (D)"
                        class="iol-v-text-field"
                        outlined
                        v-model="inputs.K22"
                        required
                        :error-messages="K22Errors"
                        type="number"
                        step="0.01"
                        @input="$v.inputs.K22.$touch()"
                        @blur="$v.inputs.K22.$touch()"
                      />
                      <v-text-field
                        label="Preop. ACD (D)"
                        class="iol-v-text-field"
                        outlined
                        v-model="inputs.ACD2"
                        required
                        :error-messages="ACD2Errors"
                        type="number"
                        step="0.01"
                        @input="$v.inputs.ACD2.$touch()"
                        @blur="$v.inputs.ACD2.$touch()"
                      />
                    </v-col>
                    <v-spacer />
                    <v-col cols="6" sm="5">
                      <v-sheet class="iol-opt-v-sheet">
                        <h3>Optional</h3><br />
                        <v-text-field
                          label="LT (mm)"
                          class="iol-v-text-field"
                          outlined
                          type="number"
                          step="0.01"
                          v-model="inputs.LT2"
                          :error-messages="LT2Errors"
                          @input="$v.inputs.LT2.$touch()"
                          @blur="$v.inputs.LT2.$touch()"
                        />
                        <v-text-field
                          label="CCT (µm)"
                          class="iol-v-text-field"
                          outlined
                          v-model="inputs.CCT2"
                          :error-messages="CCT2Errors"
                          type="number"
                          step="0"
                          @input="$v.inputs.CCT2.$touch()"
                          @blur="$v.inputs.CCT2.$touch()"
                        />
                      </v-sheet>
                    </v-col>
                  </v-row>
                  <v-row class="justify-md-space-between">
                    <v-col cols="4" md="3">
                      <v-switch
                        label="ICL"
                        v-model="inputs.isICL"
                        prepend-icon="info"
                      >
                        <v-tooltip bottom slot="prepend">
                          <template v-slot:activator="{ on, attrs }">
                              <v-icon v-on="on" v-bind="attrs" color="primary">
                                info
                              </v-icon>
                            </template>
                          <span>If an Implantable Collamer Lens was implanted at the time of biometry, activate this option. It will adapt the AL measurement</span>
                        </v-tooltip>
                      </v-switch>
                    </v-col>
                    <v-col cols="10" md="8">
                      <v-sheet class="iol-opt-v-sheet">
                        <h3>Optional</h3><br />
                        <v-row class="justify-left">
                          <v-col cols="8" md="8" offset="1">
                            <v-select
                              label="Corneal Refractive surgery"
                              :items="$parent.cornealLaserTypeItems"
                              item-text="text"
                              outlined
                              class="smooth iol-v-select"
                              v-model="inputs.cornealLaserType"
                              :disabled="!inputs.isICL"
                              return-object
                            />
                          </v-col>
                        </v-row>
                        <v-row class="justify-center">
                          <v-col cols="8" md="8">
                            <v-tooltip bottom slot="append">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  label="Pre-LVC anterior corneal radius (mm)"
                                  outlined
                                  class="iol-v-text-field"
                                  v-model="inputs.preLvcArc2"
                                  :error-messages="preLvcArc2Errors"
                                  type="number"
                                  step="0.01"
                                  :disabled="!inputs.isICL"
                                  @change="$v.inputs.preLvcArc2.$touch()"
                                  @blur="$v.inputs.preLvcArc2.$touch()"
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </template>
                              <span>If available, enter the anterior corneal radius measured before laser vision correction, in millimeters</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row class="justify-center">
                          <v-col cols="8" md="8">
                            <v-tooltip bottom slot="append">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  label="Corrected ametropia (D)"
                                  class="iol-v-text-field"
                                  outlined
                                  v-model="inputs.laserAmount2"
                                  :error-messages="laserAmount2Errors"
                                  :disabled="!inputs.isICL"
                                  type="number"
                                  step="0.01"
                                  @change="$v.inputs.laserAmount2.$touch()"
                                  @blur="$v.inputs.laserAmount2.$touch()"
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </template>
                              <span>If available, enter the absolute value of the corrected ametropia, in diopters</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-sheet>
          <v-row>
            <v-col cols="12">
              <v-divider />
              <br />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn @click="submit" color="#3B4256" class="white--text" x-large
                >Calculate</v-btn
              >
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-col cols="12" class="text-center">
              <a @click="clear" :style="$parent.resetstyle">Reset</a>
            </v-col>
          </v-row>
        </v-container>
      </form>
    </v-main>
  </v-app>
</template>

<style>
.v-application .primary--text {
  color: #3b4256 !important;
  caret-color: #3b4256 !important;
}
</style>

<script>
import { mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import {
  required,
  requiredIf,
  minValue,
  between
} from 'vuelidate/lib/validators'
import { dualSolve } from '@/graphql/queries/solve.gql'
import Logout from '@/components/Logout.vue'

let omitNull = obj => {
  let copy = { ...obj }
  Object.keys(copy)
    .filter(k => copy[k] === null)
    .forEach(k => delete copy[k])
  return copy
}

export default {
  mixins: [validationMixin],
  components: {
    Logout
  },
  validations () {
    return {
      inputs: {
        AL: { required, between: between(17, 40) },
        ACD: { required, between: between(1, 6) },
        K1: { required, between: between(30, 60) },
        K2: {
          required,
          between: between(30, 60),
          minValue: minValue(this.inputs.K1)
        },
        LT: { between: between(2.5, 8) },
        CCT: { between: between(350, 700) },
        target: { required, between: between(-4, 1.5) },
        aconst: {
          required: requiredIf(function (form) {
            return !this.inputs.is60MA
          }),
          between: between(116.5, 121) },
        preLvcArc: { between: between(5.5, 9.5) },
        laserAmount: { between: between(0.5, 12) },
        AL2: { required, between: between(17, 40) },
        ACD2: { required, between: between(1, 6) },
        K12: { required, between: between(30, 60) },
        K22: {
          required,
          between: between(30, 60),
          minValue: minValue(this.inputs.K12)
        },
        LT2: { between: between(2.5, 8) },
        CCT2: { between: between(350, 700) },
        SE2: { required, between: between(-9, 9) },
        aconst2: { required, between: between(116.5, 121) },
        power2: { required, between: between(0, 35) },
        preLvcArc2: { between: between(5.5, 9.5) },
        laserAmount2: { between: between(0.5, 12) },
        nvit: { required, between: between(1.2, 1.5) }
      }
    }
  },
  mounted () {
    if (this.$route.query.ocr) {
      this.$v.inputs.$touch()
    }
    this.inputs = { ...omitNull(this.inputs), ...omitNull(this.ginputs) }
    this.inputs.isSecond = true
  },
  data: () => {
    let ret = {
      inputs: {
        patientID: '',
        side: true,
        IOLModel: { type: 'Finevision', text: 'Finevision', aconst: 118.9 },
        aconst: 118.9,
        aconst2: 118.9,
        is60MA: false,
        isVitreous: true,
        isCornealLaser: false,
        isICL: false,
        cornealLaserType: { type: 'None', text: 'None' },
        isCornealLaser2: false,
        isICL2: false,
        cornealLaserType2: { type: 'None', text: 'None' },
        cornealSurgeryHistory: 'No corneal surgery',
        isNonPhysioCornea: false,
        isRK: false,
        keratometricIndex: 1.3375,
        vitreousType: { type: 'Vitreous', value: 1.336 },
        nvit: 1.336,
        biometer: { type: 'Other', text: 'Other' },
        isSecond: true,
        preLvcArc: '',
        laserAmount: '',
        preLvcArc2: '',
        laserAmount2: '',
        target: 0
      },
      inputs_default: {
        patientID: '',
        side: true,
        IOLModel: { type: 'Finevision', text: 'Finevision', aconst: 118.9 },
        aconst: 118.9,
        aconst2: 118.9,
        is60MA: false,
        isVitreous: true,
        isCornealLaser: false,
        isICL: false,
        cornealLaserType: { type: 'None', text: 'None' },
        isCornealLaser2: false,
        isICL2: false,
        cornealLaserType2: { type: 'None', text: 'None' },
        cornealSurgeryHistory: 'No corneal surgery',
        isNonPhysioCornea: false,
        isRK: false,
        keratometricIndex: 1.3375,
        vitreousType: { type: 'Vitreous', value: 1.336 },
        nvit: 1.336,
        biometer: { type: 'Other', text: 'Other' },
        isSecond: true,
        preLvcArc: '',
        laserAmount: '',
        preLvcArc2: '',
        laserAmount2: '',
        target: 0
      }
    }
    // if (process.env.NODE_ENV !== 'production') {
    //   ret.inputs = {
    //     ...ret.inputs,
    //     side: true,
    //     keratometricIndex: 1.3375,
    //     AL: 29.32,
    //     K1: 43.32,
    //     K2: 45.34,
    //     ACD: 3.24,
    //     LT: 4.12,
    //     CCT: 534,
    //     nvit: 1.336,
    //     biometer: { type: 'Other', text: 'Other' },
    //     IOLModel: { type: 'Finevision', text: 'Finevision', aconst: 118.9 },
    //     aconst: 118.9,
    //     isNonPhysioCornea: false,
    //     isICL: true,
    //     isRK: true,
    //     isCornealLaser: true,
    //     cornealLaserType: { type: 'Myopic', text: 'Myopic' },
    //     preLvcArc: 7,
    //     laserAmount: 9,
    //     isSecond: true,
    //     AL2: 23.32,
    //     K12: 42.64,
    //     K22: 45.28,
    //     ACD2: 4.18,
    //     LT2: 5.72,
    //     CCT2: 569,
    //     power2: 24,
    //     SE2: -3,
    //     aconst2: 118.9,
    //     isCornealLaser2: true,
    //     cornealLaserType2: { type: 'Myopic', text: 'Myopic' },
    //     preLvcArc2: 7,
    //     laserAmount2: 7.5,
    //     isICL2: false,
    //     target: -3,
    //     patientID: 'testid'
    //   }
    // }
    return ret
  },

  computed: {
    ...mapState(['appHeader', 'ginputs']),
    ALErrors () {
      const errors = []
      if (!this.$v.inputs.AL.$dirty) return errors
      !this.$v.inputs.AL.between && errors.push('value out of bounds')
      !this.$v.inputs.AL.required && errors.push('required')
      return errors
    },
    ACDErrors () {
      const errors = []
      if (!this.$v.inputs.ACD.$dirty) return errors
      !this.$v.inputs.ACD.between && errors.push('value out of bounds')
      !this.$v.inputs.ACD.required && errors.push('required')
      return errors
    },
    K1Errors () {
      const errors = []
      if (!this.$v.inputs.K1.$dirty) return errors
      !this.$v.inputs.K1.between && errors.push('value out of bounds')
      !this.$v.inputs.K1.required && errors.push('required')
      return errors
    },
    K2Errors () {
      const errors = []
      if (!this.$v.inputs.K2.$dirty) return errors
      !this.$v.inputs.K2.between && errors.push('value out of bounds')
      !this.$v.inputs.K2.required && errors.push('required')
      return errors
    },
    LTErrors () {
      const errors = []
      if (!this.$v.inputs.LT.$dirty) return errors
      !this.$v.inputs.LT.between && errors.push('value out of bounds')
      return errors
    },
    CCTErrors () {
      const errors = []
      if (!this.$v.inputs.CCT.$dirty) return errors
      !this.$v.inputs.CCT.between && errors.push('value out of bounds')
      return errors
    },
    TargetErrors () {
      const errors = []
      if (!this.$v.inputs.target.$dirty) return errors
      !this.$v.inputs.target.between && errors.push('value out of bounds')
      !this.$v.inputs.target.required && errors.push('required')
      return errors
    },
    aconstErrors () {
      const errors = []
      if (!this.$v.inputs.aconst.$dirty) return errors
      !this.$v.inputs.aconst.between && errors.push('value out of bounds')
      !this.$v.inputs.aconst.required && errors.push('required')
      return errors
    },
    preLvcArcErrors () {
      const errors = []
      if (!this.$v.inputs.preLvcArc.$dirty) return errors
      !this.$v.inputs.preLvcArc.between && errors.push('value out of bounds')
      return errors
    },
    laserAmountErrors () {
      const errors = []
      if (!this.$v.inputs.laserAmount.$dirty) return errors
      !this.$v.inputs.laserAmount.between && errors.push('value out of bounds')
      return errors
    },
    AL2Errors () {
      const errors = []
      if (!this.$v.inputs.AL2.$dirty) return errors
      !this.$v.inputs.AL2.between && errors.push('value out of bounds')
      !this.$v.inputs.AL2.required && errors.push('required')
      return errors
    },
    ACD2Errors () {
      const errors = []
      if (!this.$v.inputs.ACD2.$dirty) return errors
      !this.$v.inputs.ACD2.between && errors.push('value out of bounds')
      !this.$v.inputs.ACD2.required && errors.push('required')
      return errors
    },
    K12Errors () {
      const errors = []
      if (!this.$v.inputs.K12.$dirty) return errors
      !this.$v.inputs.K12.between && errors.push('value out of bounds')
      !this.$v.inputs.K12.required && errors.push('required')
      return errors
    },
    K22Errors () {
      const errors = []
      if (!this.$v.inputs.K22.$dirty) return errors
      !this.$v.inputs.K22.between && errors.push('value out of bounds')
      !this.$v.inputs.K22.required && errors.push('required')
      !this.$v.inputs.K22.minValue && errors.push('K12 bigger')
      return errors
    },
    LT2Errors () {
      const errors = []
      if (!this.$v.inputs.LT2.$dirty) return errors
      !this.$v.inputs.LT2.between && errors.push('value out of bounds')
      return errors
    },
    CCT2Errors () {
      const errors = []
      if (!this.$v.inputs.CCT2.$dirty) return errors
      !this.$v.inputs.CCT2.between && errors.push('value out of bounds')
      return errors
    },
    SE2Errors () {
      const errors = []
      if (!this.$v.inputs.SE2.$dirty) return errors
      !this.$v.inputs.SE2.between && errors.push('value out of bounds')
      !this.$v.inputs.SE2.required && errors.push('required')
      return errors
    },
    aconst2Errors () {
      const errors = []
      if (!this.$v.inputs.aconst2.$dirty) return errors
      !this.$v.inputs.aconst2.between && errors.push('value out of bounds')
      !this.$v.inputs.aconst2.required && errors.push('required')
      return errors
    },
    power2Errors () {
      const errors = []
      if (!this.$v.inputs.power2.$dirty) return errors
      !this.$v.inputs.power2.between && errors.push('value out of bounds')
      !this.$v.inputs.power2.required && errors.push('required')
      return errors
    },
    preLvcArc2Errors () {
      const errors = []
      if (!this.$v.inputs.preLvcArc2.$dirty) return errors
      !this.$v.inputs.preLvcArc2.between && errors.push('value out of bounds')
      return errors
    },
    laserAmount2Errors () {
      const errors = []
      if (!this.$v.inputs.laserAmount2.$dirty) return errors
      !this.$v.inputs.laserAmount2.between && errors.push('value out of bounds')
      return errors
    },
    vitreousErrors () {
      const errors = []
      if (!this.$v.inputs.nvit.$dirty) return errors
      !this.$v.inputs.nvit.between && errors.push('value out of bounds')
      !this.$v.inputs.nvit.required && errors.push('required')
      return errors
    }
  },

  methods: {
    IOLModelUpdated () {
      if (this.inputs.IOLModel.type === '60MA') {
        this.inputs.is60MA = true
        this.inputs.isSecond = false
      } else {
        this.inputs.is60MA = false
      }
      this.inputs.aconst = this.inputs.IOLModel.aconst
      this.inputs.aconst2 = this.inputs.aconst
    },
    aconstUpdated () {
      this.inputs.aconst2 = this.inputs.aconst
    },
    isNonPhysioCorneaUpdated () {
      if (this.inputs.isNonPhysioCornea && this.inputs.isSecond) {
        this.inputs.isSecond = false
      }
    },
    isRKUpdated () {
      if (this.inputs.isRK && this.inputs.isSecond) {
        this.inputs.isSecond = false
      }
    },
    vitreousTypeUpdated () {
      this.inputs.nvit = this.inputs.vitreousType.value
      if (this.inputs.vitreousType.type === 'Vitreous') {
        this.inputs.isVitreous = true
      } else {
        this.inputs.isVitreous = false
      }
    },
    focusFirstError (component = this) {
      if (component.hasError) {
        component.focus()
        return true
      }

      let focused = false

      component.$children.some(childComponent => {
        focused = this.focusFirstError(childComponent)
        return focused
      })

      return focused
    },
    submit () {
      this.$v.inputs.$touch()
      this.$nextTick(() => {
        if (this.focusFirstError()) {
          return
        }
        Object.keys(this.inputs).forEach(
          k => (this.inputs[k] = this.inputs[k] === '' ? null : this.inputs[k])
        )
        this.inputs.isICL2 = this.inputs.isICL
        this.inputs.cornealLaserType2 = this.inputs.cornealLaserType

        const inputs = { ...omitNull(this.inputs) }
        this.$store.dispatch('updateInputs', this.inputs)

        inputs['IOLModel'] = inputs['IOLModel'].type // send IOLModel type
        inputs['biometer'] = inputs['biometer'].type // send biometer type
        inputs['cornealLaserType'] = inputs['cornealLaserType'].type // send cornealLaserType type
        inputs['cornealLaserType2'] = inputs['cornealLaserType2'].type // send cornealLaserType type

        // delete inputs from complex
        inputs['isNonPhysioCornea'] = false
        inputs['isRK'] = false

        delete inputs['patientID'] // do not send patientID
        delete inputs['cornealSurgeryHistory'] // do not send cornealSurgeryHistory
        delete inputs['vitreousType'] // do not send vitreousType
        delete inputs['isVitreous'] // do not send isVitreous
        delete inputs['is60MA'] // do not send vitreousType

        const inputs2 = { ...inputs }
        // this.$store.dispatch('updateInputs2', inputs2)

        inputs2['isSecond'] = false

        this.$apollo
          .query({
            query: dualSolve,
            variables: {
              inputs,
              inputs2
            }
          })
          .then(result => {
            this.$store.dispatch('setResults', result.data.solveOD)
            this.$store.dispatch('setResults2', result.data.solveOS)
            this.$router.push('result_second')
          })
          .catch(e => {
            this.$raven.captureException(e)
          })
      })
    },
    clear () {
      Object.keys(this.inputs).forEach(k => (this.inputs[k] = this.inputs_default[k] !== null ? this.inputs_default[k] : null))
      this.$refs.first.focus()
    },
    ...mapActions(['updateInputs', 'setResults', 'setResults2', 'setAuthUser'])
  }
}
</script>
